import React from 'react';
import Format from 'types/format.type';
import Checkbox from 'components/ui-components-v2/Checkbox';
import ListItem from 'components/ui-components-v2/ListItem';
import ListItemButton from 'components/ui-components-v2/ListItemButton';
import { CreativeV2FormatHelpers } from 'components/creatives-v2/helpers/formats.helpers';
import { Item } from '..';
import { CreativeEditorV2FormatActions } from '../../creative-editor/components/creative-editor-format-actions';

import '../styles/format.scss';

interface Props {
    item: Item;
    active: boolean;
    selectable?: boolean; // If this is true, each format will have a checkbox and there is an option to enable all formats at the same time
    selected?: boolean;
    onClickItem: (item: Item) => void;
    onSelectItem?: (item: Item) => void;
}

const FormatsListItem: React.FC<Props> = ({ item, selected, selectable, active, onClickItem, onSelectItem }) => {
    const onClick = (item: Item) => {
        if (!selectable) {
            onClickItem(item);
            return;
        }

        onSelectItem?.(item);
    };

    return (
        <ListItem disablePadding disableGutters>
            <ListItemButton disableGutters={selectable} onClick={() => onClick(item)} selected={active}>
                {selectable && <Checkbox size="small" checked={selected} onChange={() => onSelectItem?.(item)} />}
                <div className="bricks-formats-list-item__preview">
                    <div className="bricks-formats-list-item__preview__placement" style={CreativeV2FormatHelpers.getFormatStyles(item as Format)}></div>
                </div>
                <div className="bricks-formats-list-item__title">{item.title}</div>
                <div className="bricks-formats-list-item__actions" onClick={(e) => e.stopPropagation()}>
                    <CreativeEditorV2FormatActions formatKey={item.key} />
                </div>
            </ListItemButton>
        </ListItem>
    );
};

export { FormatsListItem };
